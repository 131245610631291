.flex_column {
  height: 100%;
}
.flex_column .inner_content {
  padding: 30px;
  flex: 2;
  overflow-y: scroll;
}
.flex_column .inner_content .title {
  margin-bottom: 10px;
}
.flex_column .inner_content .option-list {
  display: block;
  font-size: 16px;
}
.flex_column .inner_content .option-list .option-item {
  margin: 0;
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: baseline;
}
.flex_column .inner_content .option-list .option-item.img {
  min-height: 100px;
  width: 30%;
  display: inline-block;
  text-align: left;
}
.flex_column .inner_content .option-list .option-item.img img {
  width: 150px;
  height: 150px;
}
.flex_column .inner_content .option-list .option-item .option_text {
  white-space: pre-wrap;
}
