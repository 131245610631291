.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outer_content_page .game_name {
  font-size: 20px;
  font-weight: bold;
}
.outer_content_page .game_title {
  font-size: 30px;
  font-weight: bold;
}
.outer_content_page .card_img img {
  width: 350px;
}
.footer {
  height: 100px;
  line-height: 100px;
}
.footer .anticon-yyashow {
  font-size: 60px;
}
.outer_content_page .inner_content {
  padding: 30px;
  flex: 2;
  height: 100%;
  overflow-y: scroll;
}
.swiper-container {
  width: 600px;
  height: 300px;
}
.play-list {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 60%;
  max-height: 390px;
  margin: 0 auto;
  bottom: -60%;
  overflow: hidden;
  z-index: 999;
  display: flex;
  flex-direction: column;
}
.play-list .list-title {
  position: relative;
  background: inherit;
  text-align: center;
  border-bottom: #ccc solid 2px;
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  color: #999;
}
.play-list ul.list-content {
  height: 100%;
  width: 100%;
  flex: 2;
  overflow-x: hidden;
  overflow-y: scroll;
}
.play-list ul.list-content li {
  border-bottom: #e2dfdf solid 1px;
  color: #a0a0a0;
  font-size: 16px;
  padding: 10px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 20px;
}
.play-list ul.list-content li span {
  font-size: 14px;
  overflow: hidden;
}
.play-list ul.list-content .song-play {
  display: inline-block;
  width: 17px;
  height: 15px;
  margin-right: 5px;
  background-size: cover;
}
.wrapper_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
}
.wrapper_content .inner_content {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 110%;
  width: 110%;
  margin: -5%;
  z-index: -1;
}
.wrapper_content .inner_content .bg-cover {
  height: 100%;
  width: 100%;
}
.wrapper_content .title {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 10%;
  width: 100%;
  z-index: 11;
  color: #666666;
  display: flex;
  align-items: center;
}
.wrapper_content .title .music-info {
  padding: 0 20px;
  margin: 0px auto;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}
.wrapper_content .title .music-info .song,
.wrapper_content .title .music-info .artist {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  margin: 8px;
  overflow: hidden;
}
.wrapper_content .title .music-info .artist {
  font-size: 14px;
  color: #c8c0cc;
  margin-top: 4px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 8px;
}
.wrapper_content .outer_swiper {
  width: 100%;
  height: 760px;
  position: absolute;
  left: 0;
  top: 0;
}
.wrapper_content .play-board {
  position: absolute;
  overflow: hidden;
  top: 10%;
  width: 100%;
  height: 90%;
}
.wrapper_content .play-board .play-needle {
  position: absolute;
  top: -23px;
  left: 50%;
  margin: 0px -12px;
  z-index: 10;
  width: 100px;
  transform-origin: 20px 20px;
  transition: transform 0.4s;
  -webkit-transform-origin: 20px 20px;
  -webkit-transition: -webkit-transform 0.4s;
}
.wrapper_content .play-board .resume-needle {
  transform: rotateZ(0deg);
  -webkit-transform: rotateZ(0deg);
}
.wrapper_content .play-board .pause-needle {
  transform: rotateZ(-25deg);
  -webkit-transform: rotateZ(-25deg);
}
.wrapper_content .play-board .disk-bg {
  width: 282px;
  height: 282px;
  position: absolute;
  left: 50%;
  top: 64px;
  margin: 0px -141px;
  border-radius: 141px;
}
.wrapper_content .play-board .disk-cover-animation {
  animation: rotate-disk 20s infinite normal linear;
  animation-play-state: paused;
  -webkit-animation: rotate-disk 20s infinite normal linear;
  -webkit-animation-play-state: paused;
}
.wrapper_content .play-board .disk-cover-animation-running {
  animation: rotate-disk 20s infinite normal linear;
  animation-play-state: running;
  -webkit-animation: rotate-disk 20s infinite normal linear;
  -webkit-animation-play-state: running;
}
@keyframes rotate-disk {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@-webkit-keyframes rotate-disk {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg);
  }
}
.wrapper_content .play-board .disk-cover {
  width: 560px;
  height: 560px;
  position: absolute;
  left: 50%;
  top: 130px;
  margin: 0% -280px;
}
.wrapper_content .play-board .disk-cover .album {
  width: 68%;
  margin: 16%;
}
.wrapper_content .play-board .disk-cover image {
  display: block;
  width: 100%;
}
.wrapper_content .play-board .disk-cover .disk-border {
  position: absolute;
  top: 0px;
}
.wrapper_content .aduio_footer {
  position: absolute;
  height: 150px;
  bottom: 0;
  width: 100%;
  color: #666;
}
.wrapper_content .aduio_footer .process {
  width: 90%;
  height: 40px;
  margin: 0px auto;
  left: 5%;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  align-items: center;
}
.wrapper_content .aduio_footer .process > span:first-child {
  margin-right: 20px;
}
.wrapper_content .aduio_footer .process > span:last-child {
  margin-left: 20px;
}
.wrapper_content .aduio_footer .process .process-bar {
  flex: 2;
  margin: 0;
  position: relative;
}
.wrapper_content .aduio_footer .control {
  text-align: center;
  width: 70%;
  left: 15%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.wrapper_content .aduio_footer .control .anticon-yyashow {
  font-size: 40px;
}
.wrapper_content .aduio_footer .control .anticon-yyashow.anticon-bofangliebiao {
  font-size: 40px;
}
.wrapper_content .aduio_footer .control .anticon-yyashow.anticon-bofang2,
.wrapper_content .aduio_footer .control .anticon-yyashow.anticon-zanting2 {
  font-size: 60px;
}
.wrapper_content .aduio_footer .control .c-btn {
  width: 45px;
  height: 45px;
  margin: 8px;
}
.wrapper_content .aduio_footer .control .c-btn.loop-btn {
  position: relative;
  width: 36px;
  height: 36px;
}
.wrapper_content .aduio_footer .control .c-btn.play {
  width: 62px;
  height: 62px;
  margin-bottom: 8px;
}
.wrapper_content .aduio_footer .control .c-btn.pause {
  width: 62px;
  height: 62px;
  margin-bottom: 8px;
}
.my_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
