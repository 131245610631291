

























































































































































































































































.anticon-yyashow {
  color: #9da4aa;
}
.anticon-bofang,
.anticon-zanting {
  font-size: 50px;
}
.anticon-replay {
  font-size: 20px;
}
.replay_box {
  text-align: center;
}
.radio_outer {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  .radio_item {
    width: 50%;
    display: flex;
    .radio_box {
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    .item_index {
      margin-left: 20px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .icon_img {
      vertical-align: middle;
    }
  }
}
