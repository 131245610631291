.flex_column {
  height: 100%;
}
.flex_column .inner_content {
  flex: 2;
  overflow-y: scroll;
  padding: 30px;
}
.flex_column .inner_content .title {
  margin-bottom: 10px;
}
