.progress_content {
  position: absolute;
  top: 0;
  width: 90%;
  height: 100%;
  display: flex;
  align-items: center;
}
.progress_content .anticon-yyashow {
  font-size: 25px;
  line-height: 1;
  line-height: 30px;
}
.progress_content .audio_progress {
  flex: 2;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.progress_content .audio_progress .inner_progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
