.antionc-yyashow {
  font-size: 60px;
}
.footer {
  height: 70px;
  line-height: 70rpx;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer .reset {
  width: 50px;
  height: 50px;
  background-color: lightgray;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
}
.footer .prev_ques {
  width: 60px;
  height: 30px;
  text-align: center;
}
.footer .prev_ques .prev_text {
  line-height: 30px;
  font-size: 0.77rem;
  width: 60px;
  height: 30px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: white;
}
.footer .next_ques {
  width: 60px;
  height: 30px;
  text-align: center;
}
.footer .next_ques .next_text {
  line-height: 30px;
  font-size: 0.77em;
  width: 60px;
  height: 30px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  color: white;
}
.footer .side_text {
  vertical-align: top;
}
.footer .play {
  font-size: 60px;
}
