.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outer_content_page .game_name {
  font-size: 20px;
  font-weight: bold;
}
.outer_content_page .game_title {
  font-size: 30px;
  font-weight: bold;
}
.outer_content_page .card_img img {
  width: 350px;
}
.footer {
  height: 100px;
  line-height: 100px;
}
.footer .anticon-yyashow {
  font-size: 60px;
}
.outer_content_page {
  overflow: hidden;
}
.outer_content_page .inner_content {
  padding: 30px;
  flex: 2;
  max-height: 100%;
  overflow-y: scroll;
}
.outer_content_page .inner_content .line_box {
  width: 80%;
  margin: 0 auto;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  height: 100px;
  position: relative;
  text-align: center;
}
.outer_content_page .inner_content .line_box .line {
  height: 24px;
  border-bottom: dashed 1px lightgray;
}
.outer_content_page .inner_content .line_box .text_area {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.outer_content_page .inner_content .line_box .text_area .text_input {
  font-size: 50px;
  height: 100px;
  line-height: 100px;
}
.bottom_container {
  left: 10%;
  width: 80%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
}
