









































































































































.flex_column {
  height: 100%;
  .inner_content {
    flex: 2;
    overflow-y: scroll;
    padding: 30px;
  }
}
