// 最外层框
.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .game_name {
    font-size: 20px;
    font-weight: bold;
  }
  .question_title {
    // font-size: large;
  }
  .game_title {
    font-size: 30px;
    font-weight: bold;
  }

  .card_img {
    img {
      width: 350px;
    }
  }
}

.footer {
  height: 100px;
  line-height: 100px;
  .anticon-yyashow {
    font-size: 60px;
  }
}
