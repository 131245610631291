
























































































































































































































































































































































@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
}
.swiper-container {
  width: 600px;
  height: 300px;
}

.play-list {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 60%;
  max-height: 390px;
  margin: 0 auto;
  bottom: -60%;
  overflow: hidden;
  z-index: 999;
  display: flex;
  flex-direction: column;
  .list-title {
    position: relative;
    background: inherit;
    text-align: center;
    border-bottom: #ccc solid 2px;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
    color: #999;
  }
  ul.list-content {
    height: 100%;
    width: 100%;
    flex: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    li {
      border-bottom: #e2dfdf solid 1px;
      color: #a0a0a0;
      font-size: 16px;
      padding: 10px 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-height: 20px;
      span {
        font-size: 14px;
        overflow: hidden;
      }
    }
    .song-play {
      display: inline-block;
      width: 17px;
      height: 15px;
      margin-right: 5px;
      background-size: cover;
    }
  }
}

.wrapper_content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  overflow: hidden;
  .inner_content {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 110%;
    width: 110%;
    margin: -5%;
    z-index: -1;
    .bg-cover {
      // background: rgba(0, 0, 0, 0.4);
      height: 100%;
      width: 100%;
    }
  }
  .title {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 10%;
    width: 100%;
    z-index: 11;
    color: #666666;
    // background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    .music-info {
      padding: 0 20px;
      margin: 0px auto;
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      .song,
      .artist {
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 18px;
        margin: 8px;
        overflow: hidden;
      }
      .artist {
        font-size: 14px;
        color: #c8c0cc;
        margin-top: 4px;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 8px;
      }
    }
  }

  .outer_swiper {
    width: 100%;
    height: 760px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .play-board {
    position: absolute;
    overflow: hidden;
    top: 10%;
    width: 100%;
    height: 90%;
    .play-needle {
      position: absolute;
      top: -23px;
      left: 50%;
      margin: 0px -12px;
      z-index: 10;
      width: 100px;
      transform-origin: 20px 20px;
      transition: transform 0.4s;
      -webkit-transform-origin: 20px 20px;
      -webkit-transition: -webkit-transform 0.4s;
    }
    .resume-needle {
      transform: rotateZ(0deg);
      -webkit-transform: rotateZ(0deg);
    }
    .pause-needle {
      transform: rotateZ(-25deg);
      -webkit-transform: rotateZ(-25deg);
    }
    .disk-bg {
      width: 282px;
      height: 282px;
      position: absolute;
      left: 50%;
      top: 64px;
      margin: 0px -141px;
      // background-color: rgba(251, 251, 251, 0.08);
      border-radius: 141px;
    }

    .disk-cover-animation {
      animation: rotate-disk 20s infinite normal linear;
      animation-play-state: paused;
      -webkit-animation: rotate-disk 20s infinite normal linear;
      -webkit-animation-play-state: paused;
    }

    .disk-cover-animation-running {
      animation: rotate-disk 20s infinite normal linear;
      animation-play-state: running;
      -webkit-animation: rotate-disk 20s infinite normal linear;
      -webkit-animation-play-state: running;
    }

    @keyframes rotate-disk {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }

    @-webkit-keyframes rotate-disk {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        -webkit-transform: rotateZ(360deg);
      }
    }

    .disk-cover {
      width: 560px;
      height: 560px;
      position: absolute;
      left: 50%;
      top: 130px;
      margin: 0% -280px;
      .album {
        width: 68%;
        margin: 16%;
      }
      image {
        display: block;
        width: 100%;
      }
      .disk-border {
        position: absolute;
        top: 0px;
      }
    }
  }
  .aduio_footer {
    position: absolute;
    height: 150px;
    bottom: 0;
    width: 100%;
    color: #666;
    .process {
      width: 90%;
      height: 40px;
      margin: 0px auto;
      left: 5%;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      display: flex;
      align-items: center;
      & > span:first-child {
        margin-right: 20px;
      }
      & > span:last-child {
        margin-left: 20px;
      }
      .process-bar {
        flex: 2;
        margin: 0;
        position: relative;
      }
    }
    .control {
      text-align: center;
      width: 70%;
      left: 15%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .anticon-yyashow {
        font-size: 40px;
        &.anticon-bofangliebiao {
          font-size: 40px;
        }
        &.anticon-bofang2,
        &.anticon-zanting2 {
          font-size: 60px;
        }
      }
      .c-btn {
        width: 45px;
        height: 45px;
        margin: 8px;
        &.loop-btn {
          position: relative;
          width: 36px;
          height: 36px;
        }
        &.play {
          width: 62px;
          height: 62px;
          margin-bottom: 8px;
        }
        &.pause {
          width: 62px;
          height: 62px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
.my_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
