.outer_content_page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.outer_content_page .game_name {
  font-size: 20px;
  font-weight: bold;
}
.outer_content_page .game_title {
  font-size: 30px;
  font-weight: bold;
}
.outer_content_page .card_img img {
  width: 350px;
}
.footer {
  height: 100px;
  line-height: 100px;
}
.footer .anticon-yyashow {
  font-size: 60px;
}
pre {
  white-space: pre-line;
}
