





























































































































































@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
  .video_box {
    text-align: center;
    width: 500px;
    margin: 0 auto;
  }
  .rich_text_container {
    height: 300px;
    width: 500px;
    margin: 0 auto;
    overflow: scroll;
  }
}
