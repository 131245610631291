





























































































































































































@import './index.less';
.outer_content_page {
  .inner_content {
    padding: 30px;
    flex: 2;
    height: 100%;
    overflow-y: scroll;
  }
}
