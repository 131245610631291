

























































.radio_outer {
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  .radio_item:nth-of-type(odd) {
    margin-right: 5%;
  }
  .radio_item {
    display: block;
    width: 45%;
    margin-top: 50rpx;
    display: flex;
    align-items: center;
    position: relative;
    .item_radio {
      position: absolute;
      left: 0;
      opacity: 0;
    }
    .radio_img {
      width: 100%;
    }
    .item_index {
      margin-left: 20rpx;
      margin-right: 15rpx;
      vertical-align: middle;
    }
    .icon_box {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      text-align: center;
      display: flex;
      .icon_right,
      .icon_wrong {
        color: white;
        font-size: 60rpx;
        margin: auto;
      }
    }
  }
}
