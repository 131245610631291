


































































































































































































































































































































































































@import './index.less';
.outer_content_page {
  overflow: hidden;
  .inner_content {
    padding: 30px;
    flex: 2;
    max-height: 100%;
    overflow-y: scroll;
    .line_box {
      width: 80%;
      margin: 0 auto;
      border-top: solid 1px black;
      border-bottom: solid 1px black;
      height: 100px;
      position: relative;
      text-align: center;
      .line {
        height: 24px;
        border-bottom: dashed 1px lightgray;
      }
      .other_text_area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-size: 70px;
        .other_choose_text {
          margin-right: 10rpx;
        }
      }
      .text_area {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        .choose_text {
          font-size: 90px;
          margin-right: 10px;
        }
      }
    }
    .word_container {
      margin: 0 auto;
      margin-top: 80px;
      font-size: 40px;
      width: 80%;
      .word_text {
        margin-right: 25px;
        border-radius: 8px;
        padding: 0 15px;
        margin-bottom: 10px;
        color: white;
        display: inline-block;
      }
    }
  }
}
.bottom_container {
  left: 10%;
  width: 80%;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
}
