






































































































































































.flex_column {
  height: 100%;
  .inner_content {
    padding: 30px;
    flex: 2;
    overflow-y: scroll;
    .title {
      margin-bottom: 10px;
    }
    .option-list {
      display: block;
      font-size: 16px;
      .option-item {
        margin: 0;
        font-size: 16px;
        margin-bottom: 10px;
        display: flex;
        align-items: baseline;
        &.img {
          min-height: 100px;
          width: 30%;
          display: inline-block;
          text-align: left;
          img {
            width: 150px;
            height: 150px;
          }
        }
        .option_text {
          white-space: pre-wrap;
        }
      }
    }
  }
}
