




















.submit {
  width: 100%;
  margin-top: 24px;
}
