.keyboard {
  z-index: 9;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: #d2d5db;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 10px 0;
}
.td {
  font-family: HelveticaNeue, HelveticaNeue-Regular, HelveticaNeue-Light;
  text-align: center;
  font-size: 30px;
  width: 74px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  margin: 5px 5px;
  color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.35);
}
.td_num {
  width: 56px;
}
.td_mid {
  width: 96px;
}
.td_nor {
  width: 84px;
  margin: 10px 4px;
}
.td_big_right {
  margin-right: 44px;
}
.td_big_left {
  margin-left: 44px;
}
.td_spec_fin {
  width: 23%;
  justify-self: flex-end;
  background-color: #09f;
  color: #fff;
}
.icon-shift,
.icon-shift-on {
  width: 28px;
  height: 15px;
  position: relative;
  top: 2px;
}
.icon-del,
.icon-del-hover {
  width: 20px;
  height: 15px;
  position: relative;
  top: 2px;
}
.td_spec_shift,
.td_spec_del {
  position: absolute;
  width: 84px;
  bottom: 70px;
  background-color: rgba(119, 136, 153, 0.5);
}
.td_spec_shift {
  left: 5px;
}
.td_spec_del {
  right: 5px;
}
/* 删除hover */
.icon-del-hover {
  display: none;
}
.td_spec_del.board_bg_spec .icon-del {
  display: none;
}
.td_spec_del.board_bg_spec .icon-del-hover {
  display: inline-template;
}
.icon-shift-on {
  display: none;
}
.td_spec_shift.on .icon-shift {
  display: none;
}
.td_spec_shift.on .icon-shift-on {
  display: inline-template;
}
.board_bg {
  box-shadow: 0 0 0 #e5e5e5;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.fnkey-wrap {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 0 5px;
}
.fnkey-wrap .td {
  font-size: 28px;
}
.space {
  flex: 1;
  width: auto;
}
.trans,
.done,
.space {
  width: 150px;
}
.trans {
  background: rgba(119, 136, 153, 0.5);
}
.done {
  background: #007aff;
  color: #fff;
  font-family: HelveticaNeue-Light;
}
