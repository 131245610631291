.ant-radio-group {
  display: block;
  width: 100%;
}
.ant-radio-group .ant-radio-wrapper {
  font-size: 16px;
}
.radio_outer {
  display: flex;
  flex-wrap: wrap;
}
.radio_outer .radio_item:nth-of-type(odd) {
  margin-right: 5%;
}
.radio_outer .inner_item {
  width: 50%;
  position: relative;
  margin: 0 auto;
}
.radio_outer .radio_item {
  display: block;
  width: 45%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}
.radio_outer .radio_item .item_radio {
  position: absolute;
  left: 0;
  opacity: 0;
}
.radio_outer .radio_item .radio_img {
  width: 100%;
}
.radio_outer .radio_item .item_index {
  margin-left: 10px;
  margin-right: 15px;
  vertical-align: middle;
}
.radio_outer .radio_item .icon_box {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  text-align: center;
  display: flex;
}
.radio_outer .radio_item .icon_box .icon_right,
.radio_outer .radio_item .icon_box .icon_wrong {
  color: white;
  font-size: 60px;
  margin: auto;
}
